import React, { useEffect } from "react";
// import { createRoot } from "react-dom/client";
import ReactDOM from 'react-dom/client';
import App from "./components/App";
import "./index.css";
import AuthService from "./Shared/Utilities/AuthService";
import * as microsoftTeams from "@microsoft/teams-js";
import { BrowserRouter } from "react-router-dom";
// import { AppRoute } from './router/router';

  const root = ReactDOM.createRoot(document.getElementById('root'));
  console.log("indexJsx",window.location?.href);
  sessionStorage.removeItem('themeValue');

  if(!window.location?.ancestorOrigins?.contains('https://teams.microsoft.com') && (window.location?.href?.includes('https://mtultimamobileprod.z30.web.core.windows.net/') || window.location?.href?.includes('https://um.ltimindtree.com/')) && !window.location?.href?.includes('/index.html')) {
    console.log("from Browser***");
    sessionStorage.setItem('fromBrowser',true);
    // if (window.location.href?.includes('home?ref=')){
    //   console.log("refreshTokeee**");
    //   alert(`home ref,..${window.location.href}`);
    //   navigate('/card');
    // }
    // console.log("SSO Redirectionn**");
    // AuthService.ssoRedirection();
  }
  else{
    sessionStorage.setItem('fromBrowser',false);
    microsoftTeams.app.initialize();
    // if(!sessionStorage.getItem('mobileTeamsToken')){
    microsoftTeams.authentication.getAuthToken().then((result) => {
      sessionStorage.setItem('mobileTeamsToken', result);
      // console.log("teams Token",result);
      let decodedTeamsToken = AuthService.decodeToken(result);
      // console.log("decodedTeamsToken QA",decodedTeamsToken);
      let teamsTokenUpn = AuthService.readUserInfoFromToken(decodedTeamsToken).upn;
      // console.log("teamsTokenUpn",teamsTokenUpn);
      sessionStorage.setItem('teamsTokenUpn', teamsTokenUpn);
      sessionStorage.setItem('nameoftheUser', AuthService.readUserInfoFromToken(decodedTeamsToken).name);
    }).catch((error) => {
      console.log("Error getting token: ");
    })
    // }
    // else {
    //   console.log("it is there in session");
    // }
  }



root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
//     <React.StrictMode>
//         <AppRoute />
//    </React.StrictMode>
);
