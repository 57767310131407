export const favAppInfo = {
    appInfo : [],
    setFavAppInfo(data){
        this.appInfo = data;
    },
    getFavAppInfo(){
        return this.appInfo;
    }
}
export const loggedInUserInfo = {
    info : {},
    setLoggedInUserInfo(data){
        this.info = data;
    },
    getLoggedInUserInfo(){
        return this.info;
    }
}

export const selectedFavApp = {
    favApp : {},
    setSelectedFavApp(data){
        this.favApp = data;
    },
    getSelectedFavApp(){
        return this.favApp;
    }
}

export const FilteredsoftwareList = {
    softwareList : [],
    setFilteredsoftwareList(data){
        this.softwareList = data;
    },
    getFilteredsoftwareList(){
        return this.softwareList;
    }
}

export const FilteredFavAppList = {
    favAppList : [],
    setFilteredFavAppList(data){
        this.favAppList = data;
    },
    getFilteredFavAppList(){
        return this.favAppList;
    }
}

export const StatsInfo = {
    statsData : {},
    setStatsInfo(data){
        this.statsData = data;
    },
    getStatsInfo(){
        return this.statsData; 
    }
}

export const scrollInfo = {
    scrollPosition : {},
    setScrollInfo(data){
        this.scrollPosition = data;
    },
    getScrollInfo(){
        return this.scrollPosition; 
    }
}
export const pullToRefreshEnable = {
    refresh : false,
    setPullToRefresh(data) {
        this.refresh = data;
    },
    getPullToRefresh() {
        return this.refresh; 
    }
}