import React, { useEffect, useState }  from "react";
import { useLocation } from "react-router-dom";
import './Iframe.css';
import { useNavigate } from "react-router-dom/dist";
import { Button, makeStyles } from '@fluentui/react-components';
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  tokens,
} from "@fluentui/react-components";
import { TeamsFxContext } from "../Context";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import config from "../sample/lib/config";
import constantFile from '../../Shared/Utilities/Constant';
import {setAppClickObserver , setPullToRefreshObserver} from '../../Shared/Utilities/sharedDataObservables';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { classNames } from "../../Shared/Utilities/CommonService";
import { pullToRefreshEnable } from "../../Shared/Utilities/sharedDataService";
import WorkspaceInfo, { WorkspaceAppsInfo } from "../../Shared/Utilities/WorkspaceInfo";

// import { Link } from "react-router-dom";

// class Iframe extends React.Component {
//     render() {
//       return (
//         <div>
//             <Link to='/card'>back</Link>
//           <h1>Iframe Statement</h1>
//         </div>
//       );
//     }
//   } 
// export default Iframe;
const useStyles = makeStyles({
  root: {
    backgroundColor: '#064674',
    color: '#fff'
  },
});

const Iframe = () => {
  const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint,
    clientId: config.clientId,
  });
  const location = useLocation();
  const navigate = useNavigate();
  const [mode, setMode] = useState('');
  const data = location.state;
  const styles = useStyles();
  let constants = constantFile;
  const closeDashboard = () => {
    setAppClickObserver.sendAppClicked(false);
    navigate('/card'); 
  }
  const navigateToUrl = () => {
    window.open(data.browserUrl);
  }

  const getSoftwareName = (data) => {
    let resultString = ``;
    let resultArr = constants.softwareCodesList?.filter((list) => list.softwareCode === data.softwareCode);
    if(resultArr?.length !== 0){  
      resultString = `${resultArr[0]?.softwareName}`;
    } 
    else resultString = `${data.softwareName}`;
    return resultString;
  }

  useEffect(() => {
    // loadmaindartjs()
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => onSelectMode(e.matches ? 'dark' : 'light'));
    onSelectMode(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
    setAppClickObserver.sendAppClicked(true);
    sessionStorage.setItem('checkAppOpen',true);
    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {
      });
    }
  })

  const handleRefresh = () => {
    console.log("refresh iframee");
    pullToRefreshEnable.setPullToRefresh(true);
    sessionStorage.setItem('AppOpen',false);
    sessionStorage.removeItem('scrollPosition');
    if( WorkspaceInfo.getWorkspacInfo()?.length !== 0 || WorkspaceAppsInfo.getWorkspaceSoftwareList()?.length !== 0 ) sessionStorage.setItem('isPulltoRefresh',true);
    else sessionStorage.setItem('isPulltoRefresh',false);
    setPullToRefreshObserver.sendPullToRefresh(true);
    navigate('/card');
  }

  const loadmaindartjs = () => {
    console.log("inside");
    var scripttag = document.createElement('script');
    scripttag.src = './main.dart.js';
    scripttag.type = 'application/javascript';
    document.getElementById('flutterpreview').append(scripttag);
 }

 const onSelectMode = (mode) => {
    setMode(mode);
  }

  return (
    <PullToRefresh onRefresh={handleRefresh} resistance={5}>
        <div className="iframe_Class">
            <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
              <FluentProvider
                theme={
                  (themeString === "dark" || mode === "dark")
                    ? {
                      ...teamsDarkTheme,
                      colorNeutralBackground3: `${constants.blackColor}`,
                    }
                    : (themeString === "contrast"  || mode === "dark")
                      ? {
                        ...teamsHighContrastTheme,
                        colorNeutralBackground3: `${constants.blackColor}`,
                      }
                      : {
                        ...teamsLightTheme,
                        colorNeutralBackground3: `${constants.greyColor}`,
                      }
                }
                style={{ background: tokens.colorNeutralBackground3 }}
              >
                <div className="iframe_header">
                  <div className="left_header">
                  {(themeString === `dark` || themeString === `contrast` || mode === `dark`) ? <img className="back_arrow" src="/chevron_left_white.svg" alt="backImg" onClick={closeDashboard} /> :
                    <img className="back_arrow" src="/chevron_left.svg" alt="backImg" onClick={closeDashboard} />}
                    <div className="title_class">
                      <div className="dashboard_img">
                        <img src={data?.iconUrl} className="image_class" alt="icon" />
                      </div>
                      <div className="dashboard_title">{data?.softwareName}</div>
                    </div>
                  </div>
                  {/* { data?.showHeader && <div className="right_header">
            <Button className={styles.root} onClick={navigateToUrl}>Open In Browser</Button>
            </div> } */}
                </div>
              </FluentProvider>
            </TeamsFxContext.Provider>
            {/* <iframe src={data?.browserUrl} className="powerBiScreen" id="powerBiScreen" style={{height: '100%',width:'100%'}}
          ></iframe> */}
            <iframe src={data.object}  className={classNames('powerBiScreen',  getSoftwareName(data) === 'Seat Allocation' && 'seatAllocation_powerBiScreen', getSoftwareName(data) === 'iTime' && 'timesheet_powerBiScreen')} loading='eager' allow="camera"></iframe>
            {/* <iframe src={data.browserUrl} className="powerBiScreen" loading='eager' allow="camera"></iframe> */}
            {/* <div id="flutterpreview"></div> */}
        </div>
    </PullToRefresh> 
  )
}

export default Iframe;