export const getSortedOrder = (prop) => {
    return function (a, b) {
      if (a[prop].toUpperCase() > b[prop].toUpperCase()) {
        return 1;
      } else if (a[prop].toUpperCase() < b[prop].toUpperCase()) {
        return -1;
      }
      return 0;
    }
}

export const getObjectUrl = (appUrl,device) => {
  let key = (device === 'web') ? 'web' : 'mobile';
  let resulturl = appUrl;
  let splitUrls = appUrl.split(',');
  if(splitUrls?.length != 0){
    splitUrls?.forEach((url) => {
      let urlSpilt = url.split('::');
      // console.log("urlSPlit",urlSpilt);
      if(urlSpilt.includes(`${key}`)) {
        resulturl = urlSpilt[1];
        return;
      } 
    })
  }
  return resulturl;
}

export const RefreshPage = {
  isRefresh : false,
  setRefreshPage(data){
      this.isRefresh = data;
  },
  getRefreshPage(){
      return this.isRefresh;
  }
}
export const classNames = (...args) => {
  return args.filter(Boolean).join(' ');
}

export const setUserStatisticsData = (
  eventTpye,
  canvasType,
  canvasCode,
  softwareName,
  softwareCode,
  projectCode,
  mid
) => {
  let properties;
  let date = new Date();
  let FormatedDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + (date.getDate())).slice(-2) + " " + ('0' + (date.getHours())).slice(-2) + ':' + ('0' + (date.getMinutes())).slice(-2) + ':' + date.getSeconds() + '.' + date.getMilliseconds() + 'Z';
  if (projectCode) {
    properties = {
      eventTpye: eventTpye,
      paneType: canvasType,
      paneId: canvasCode,
      appName: softwareName,
      appId: softwareCode,
      projectId: projectCode,
      timestamp: FormatedDate,
      mid : mid
    };
  } else {
    properties = {
      eventTpye: eventTpye,
      paneType: canvasType,
      paneId: canvasCode,
      appName: softwareName,
      appId: softwareCode,
      projectId: 'none',
      timestamp: FormatedDate,
      mid : mid
    };
  }
  return properties;
} 