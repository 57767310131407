import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import './PowerBiDashboard.css';
import '../Iframe/Iframe.css';
import { getPowerbiTokenApi } from "../../Shared/Utilities/API";
import constantFile from '../../Shared/Utilities/Constant';
import AuthService from '../../Shared/Utilities/AuthService';
import {
    FluentProvider,
    teamsLightTheme,
    teamsDarkTheme,
    teamsHighContrastTheme,
    tokens,
} from "@fluentui/react-components";
import { TeamsFxContext } from "../Context";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import config from "../sample/lib/config";
import { setAppClickObserver , setPullToRefreshObserver } from '../../Shared/Utilities/sharedDataObservables';
import WorkspaceInfo, { WorkspaceAppsInfo } from "../../Shared/Utilities/WorkspaceInfo";
import { pullToRefreshEnable } from "../../Shared/Utilities/sharedDataService";
import PullToRefresh from 'react-simple-pull-to-refresh';


const PowerBiDashboard = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const data = location.state;
    const [errorMsg, setErrorMsg] = useState('');
    const [powerBitoken, setPowerBIToken] = useState('');
    const [sourceUrl, setSourceUrl] = useState('');
    const [reportId, setReportId] = useState('');
    const [isLoader, setLoader] = useState(false);
    const [isPowerBIEmbed, setPowerBIEmbed] = useState(false);
    const [mode, setMode] = useState('');
    const properties = {};
    let constants = constantFile;
    // const reportId = data?.browserUrl?.substring(data?.browserUrl.indexOf('=') + 1, data?.browserUrl.indexOf('&'));
    const authService = AuthService;
    const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
        initiateLoginEndpoint: config.initiateLoginEndpoint,
        clientId: config.clientId,
    });

    useEffect(() => {
        setLoader(true);
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => onSelectMode(e.matches ? 'dark' : 'light'));
        onSelectMode(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
        // const accessToken = sessionStorage.getItem('mobilePowerBIToken');
        if (sessionStorage.getItem('mobilePowerBIToken')) {
            let validBIToken = authService.checkTokenExpiry(sessionStorage.getItem('mobilePowerBIToken'));
            if (!validBIToken) {
                getPowerBIToken();
            }
            else {
                setPowerBIToken(sessionStorage.getItem('mobilePowerBIToken'));
                embedReport(data.object);
            }
        }
        else getPowerBIToken();
        // Remove listener
        return () => {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {
            });
        }
    }, []);

    const getPowerBIToken = async () => {
        try {
            let response = await getPowerbiTokenApi();
            if (response.data === null || response.data === 'null') setPowerBIEmbed(false);
            else {
                if (response.data?.accessToken) {
                    sessionStorage.setItem('mobilePowerBIToken', response.data?.accessToken);
                    setPowerBIToken(response.data?.accessToken);
                    embedReport(data.object);
                }
                else setPowerBIEmbed(false);
            }
        }
        catch (err) {
            setPowerBIEmbed(false);
            setLoader(false);
            setErrorMsg('Error While We fetch the powerbiToken!');
        }
    }

    // const accessToken = sessionStorage.getItem('mobilePowerBIToken');
    // console.log("mobileToken from session storage", accessToken);
    const closeDashboard = () => {
        setAppClickObserver.sendAppClicked(false);
        navigate('/card');
        // window.history.pushState(null, null, "/card");
    }

    const handleRefresh = () => {
        pullToRefreshEnable.setPullToRefresh(true);
        sessionStorage.setItem('AppOpen',false);
        sessionStorage.removeItem('scrollPosition');
        if( WorkspaceInfo.getWorkspacInfo()?.length !== 0 || WorkspaceAppsInfo.getWorkspaceSoftwareList()?.length !== 0 ) sessionStorage.setItem('isPulltoRefresh',true);
        else sessionStorage.setItem('isPulltoRefresh',false);
        setPullToRefreshObserver.sendPullToRefresh(true);
        navigate('/card');
      }

    const embedReport = (sourceUrl) => {
        console.log("EMPORT", sourceUrl);
        // this.reportClass = 'powerbi-report-container';
        // https://app.powerbi.com/reportEmbed?reportId=fc9a5886-0d3d-4231-a3a9-97c1bca3f4a8&appId=11577503-901d-44cb-99e8-60b636d108f0&ctid=ff355289-721e-4dd7-a663-afec62ab9d54
        let startindex = sourceUrl.indexOf('?reportId=');
        let strOut = sourceUrl.substr(startindex);
        let splitarr = strOut.split('&');
        let reportId = splitarr[0].split('=');
        const searchParams = new URLSearchParams(sourceUrl);
        let appIdfromUrl = '';
        if (searchParams.has('appId')) appIdfromUrl = searchParams.get('appId');
        let formatedSourceUrl = (appIdfromUrl) ? `https://app.powerbi.com/reportEmbed?reportId=${reportId[1]}&appId=${appIdfromUrl}` : `https://app.powerbi.com/reportEmbed?reportId=${reportId[1]}`;
        console.log("formated url", formatedSourceUrl);
        setSourceUrl(formatedSourceUrl);
        setReportId(reportId[1]);
        setPowerBIEmbed(true);
    }
    const onSelectMode = (mode) => {
       setMode(mode);
    }

    return (
        <PullToRefresh onRefresh={handleRefresh} resistance={5}>
            <div className="iframe_Class">
                <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
                    <FluentProvider
                        theme={
                            (themeString === "dark" || mode === "dark")
                                ? {
                                    ...teamsDarkTheme,
                                    colorNeutralBackground3: `${constants.blackColor}`,
                                }
                                : (themeString === "contrast" || mode === "dark")
                                    ? {
                                        ...teamsHighContrastTheme,
                                        colorNeutralBackground3: `${constants.blackColor}`,
                                    }
                                    : {
                                        ...teamsLightTheme,
                                        colorNeutralBackground3: `${constants.greyColor}`,
                                    }
                        }
                        style={{ background: tokens.colorNeutralBackground3 }}
                    >
                        <div className="iframe_header">
                            <div className="left_header">
                            {(themeString === `dark` || themeString === `contrast` || mode === `dark`) ? <img className="back_arrow" src="/chevron_left_white.svg" alt="backImg" onClick={closeDashboard} /> :
                                <img className="back_arrow" src="/chevron_left.svg" alt="backImg" onClick={closeDashboard} />}
                                {/* <img src="/arrow-back.svg" alt="backImg" className="back_arrow" onClick={closeDashboard} /> */}
                                <div className="title_class">
                                <div className="dashboard_img">
                                    <img src={data?.iconUrl}  className="image_class" alt="icon" />
                                </div>
                                <div className="dashboard_title">{data?.softwareName}</div>
                                </div>
                            </div>
                        </div>
                    </FluentProvider>
                </TeamsFxContext.Provider>
                {
                    isPowerBIEmbed === true ?
                        <PowerBIEmbed
                            embedConfig={{
                                type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                                id: `${reportId}`,
                                embedUrl: `${sourceUrl}`,
                                // pageView: 'oneColumn',
                                accessToken: `${powerBitoken}`,
                                permissions: models.Permissions.All,
                                settings: {
                                    panes: {
                                        filters: {
                                            expanded: false
                                        }
                                    },
                                }
                            }}
                            eventHandlers={
                                new Map([
                                    ['loaded', function () { console.log('Report loaded'); }],
                                    ['rendered', function () { console.log('Report rendered'); }],
                                    ['error', function (event) { console.log(event.detail, powerBitoken); setPowerBIEmbed(false); }],
                                    ['visualClicked', () => console.log('visual clicked')],
                                    ['pageChanged', (event) => console.log(event)],
                                ])
                            }
                            cssClassName={"reportClass"}
                            getEmbeddedComponent={
                                (embeddedReport) => {
                                    window.report = embeddedReport;
                                }
                            }
                        /> :
                        <iframe src={data.object} className="powerBiScreen" loading='eager'></iframe>
                }
            </div>
        </PullToRefresh>
    )
}
export default PowerBiDashboard;