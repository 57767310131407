import React, { useEffect, useState, useRef } from "react";
import './CardDetails.css';
import { app } from "@microsoft/teams-js";
// import axios from "axios";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom/dist";
import constantFile from '../../Shared/Utilities/Constant';
import WorkspaceInfo, { WorkspaceAppsInfo } from "../../Shared/Utilities/WorkspaceInfo";
import { onClickApi, getSoftwaresOnGroupCodeApi, getSoftwareListOnCanvas, genericCanvasWorkspaceApi, saveCanvas, addAsFavAppApi, updateCanvas, createMobileCanvas , postUserStatData, getStatsData } from "../../Shared/Utilities/API";
import { getSortedOrder, getObjectUrl, RefreshPage, classNames , setUserStatisticsData} from "../../Shared/Utilities/CommonService";
import UserProfile from "../../Shared/Utilities/ProfileData";
// import { pages } from "@microsoft/teams-js";
import * as microsoftTeams from "@microsoft/teams-js";
import AuthService from '../../Shared/Utilities/AuthService';
import { loggedInUserInfo, selectedFavApp, FilteredsoftwareList, FilteredFavAppList,StatsInfo , scrollInfo , pullToRefreshEnable} from '../../Shared/Utilities/sharedDataService';
import toastr from "toastr";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@fluentui/react-components';
import { setLoaderObserver, setSearchExpandObserver,setAppClickObserver , setPullToRefreshObserver } from './../../Shared/Utilities/sharedDataObservables';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  PointerSensor,
  useSensor,
  KeyboardSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy // <== doesn't break if this is rectSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  tokens,
} from "@fluentui/react-components";
import { TeamsFxContext } from "../Context";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import config from "../sample/lib/config";
import PullToRefresh from 'react-simple-pull-to-refresh';

// class CardDetails extends React.Component {
//     render() {
//       return (
//         <div>
//           <h1>CardDetails Statement</h1>
//          <Link to= '/iframe'>Iframe compoenent</Link>
//         </div>
//       );
//     }
// } 
// export default CardDetails;

const CardDetails = () => {
  const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint,
    clientId: config.clientId,
  });
  const navigate = useNavigate();
  // const [canvasInfo , setCanvasRes] = useState({});
  const [softwares, setSoftwareResponse] = useState([]);
  const [softwaresCopy, setSoftwareResponseCopy] = useState([]);
  const [favsoftwares, setfavSoftwareResponse] = useState([]);
  const [favsoftwaresCopy, setfavSoftwareResponseCopy] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [noData, setNoData] = useState('');
  let result = (sessionStorage.getItem('checkAppOpen') === 'true') ? false : true;
  const [isLoader, setLoader] = useState(result);
  const [cardView, setCardView] = useState(false);
  // const [loggedInUserInfo , setLoggedInUserInfo] = useState({});
  const properties = {};
  let onclickRes = {};
  let constants = constantFile;
  toastr.options = {
    closeButton: true,
    progressBar: true,
    positionClass: "toast-top-right",
    timeOut: 2000,
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    toastClass: 'toast-container',
  };
  const [value, setValue] = useState(0);
  // const dragPerson = useRef(0);
  // const draggedOverPerson = useRef(0);
  let searchResult = localStorage.getItem('searchString') ? `${localStorage.getItem('searchString')}` : "";
  const [searchInput, setSearchInput] = useState(searchResult);
  const [searchOpen, setSearchOpen] = useState(true);
  const [favsoftwaresIDs, setFavSoftwareIds] = useState([]);
  const [visibility,setVisibilityValue] = useState("");
  const [ readyState,setreadyState ] = useState("");
  const [ appFromBrowser , setAppFromBrowser] = useState(false);
  const [ deviceInfo , setDeviceInfo] = useState('');
  const [mode, setMode] = useState('');
  let fromBrowserRes = sessionStorage.getItem('fromBrowser') ? sessionStorage.getItem('fromBrowser') : "";
  const [fromBrowser, setFromBrowser] = useState(fromBrowserRes);
  // const [scrollPosition,setScrollPosition] = useState(resultScrollPosition);
  // const scrollRef = useRef();
  // const styles = useStyles();
  // const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  // const sensors = useSensors(useSensor(MouseSensor, { activationConstraint: { distance: 100 } }), useSensor(TouchSensor));
  // const sensors = useSensors( useSensor(PointerSensor,{ activationConstraint: { delay: 100 } }),useSensor(TouchSensor));
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 5
    }
  });
  const mouseSensor = useSensor(MouseSensor, { activationConstraint: { distance: 10 } });
  const touchSensor = useSensor(TouchSensor, { activationConstraint: { delay: 300, tolerance: 10 } });
  // const touchSensor = useSensor(TouchSensor, {
  //   activationConstraint: {
  //     delay: 50,
  //     tolerance: 1
  //   }
  // })
  const keyboardSensor = useSensor(KeyboardSensor, {
    coordinateGetter: sortableKeyboardCoordinates
  })
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);
  const fabStyle = {
    position: 'absolute',
    bottom: 20,
    right: 50,
  };

  const fabColor = {
    color: '#fff',
    bgcolor: `#999`,
    '&:hover': {
      bgcolor: `${tokens.colorNeutralBackground1}`,
    },
  };

  // const fabStyles = { ...fabStyle, ...fabColor };
  const fabStyles = { ...fabColor };



  const appClick = (e, software) => {
    e.preventDefault();
    // if(searchOpen == true && searchInput?.length == 0 && sessionStorage.getItem('browser') === 'true') {
    //   setSearchOpen(false);
    //   setSearchExpandObserver.sendSearchExpand(false);
    // }
    sessionStorage.setItem('AppOpen',false);
    // setVisibilityValue(`app click ${software.softwareCode} ${sessionStorage.getItem('AppOpen')}`);
    if(constants?.statsApps?.includes(software.softwareCode)) {
      sessionStorage.setItem('AppOpen',true);
      // setVisibilityValue(`app click IF ${software.softwareCode} ${sessionStorage.getItem('AppOpen')}`);
    }
    // else sessionStorage.setItem('AppOpen',false);
    let deviceInfo = (sessionStorage.getItem('deviceInfo')) ? (sessionStorage.getItem('deviceInfo')) : 'browser';
    let stats = setUserStatisticsData( 
      `${deviceInfo}`,
      "MOBILE",
      software.canvasCode,
      software.softwareName,
      software.softwareCode,
      software?.projectCode, 
      '');
    setItemsInStorage(stats);
    callOnClickApi(software);
    if(localStorage.getItem('userStatistics') && JSON.parse(localStorage.getItem('userStatistics'))?.length >= 5) callUserStatistics(JSON.parse(localStorage.getItem('userStatistics')));
  }

  const setItemsInStorage = (stats) => {
    let startsArr = [];
    if(localStorage.getItem('userStatistics')) startsArr = JSON.parse(localStorage.getItem('userStatistics'));
    startsArr.push(stats);
    const result = areKeysSame(startsArr);
    if(result) localStorage.setItem('userStatistics',JSON.stringify(startsArr));
  }
  const areKeysSame = (arr) => {
      if(arr.length === 0) return true;
      const keys = Object.keys(arr[0]);
      for(let i = 1; i < arr.length ; i++) {
        const objKeys = Object.keys(arr[i]);
        if(keys.length !== objKeys.length || !keys.every(key => objKeys.includes(key))) return false;
      }
      return true;
  }

  const callUserStatistics = async(stats) => {
    let payLoad = [];
    // payLoad.push(stats);
    payLoad = stats.map(obj => ({
      ...obj,
     mid : loggedInUserInfo.getLoggedInUserInfo()?.mid
    }));
    try {
      let response = await postUserStatData(payLoad);
      localStorage.setItem('userStatistics',[]);
    }
    catch(err) {
      console.log("err in stats",err);
    }
  }

  const handleChange = (event, newValue) => {
    sessionStorage.setItem('currentSelectedTab',newValue);
    setValue(newValue);
    let softwareList = Object.assign([], FilteredsoftwareList.getFilteredsoftwareList());
    let favAppslist = Object.assign([], FilteredFavAppList.getFilteredFavAppList());
    let favAppslistCodes = favAppslist?.map((list) => { return list?.softwareCode });
    if(newValue === 1) {
      // let dynamicApps = WorkspaceInfo.getWorkspacInfo()?.nodes?.filter((list) => constants.statsApps.includes(list.software.softwareCode));
      let dynamicApps = WorkspaceAppsInfo.getWorkspaceSoftwareList()?.filter((list) => constants.statsApps.includes(list.softwareCode));
      if(dynamicApps?.length !== 0) {
        if(Object?.keys(StatsInfo.getStatsInfo())?.length !== 0) addStatsForApps(StatsInfo.getStatsInfo(),true);
        else getAppStatsInfo(dynamicApps);
      }
    }
    setSoftwareResponse(softwareList);
    setSoftwareResponseCopy(softwareList);
    setfavSoftwareResponse(favAppslist);
    setfavSoftwareResponseCopy(favAppslist);
    setFavSoftwareIds(favAppslistCodes);
    if (searchInput) filteredSearch(searchInput);
  };

  // const updateList = () => {
  //   const favAppClone = [...favsoftwares];
  //   const temp = favAppClone[dragPerson.current];
  //   favAppClone[dragPerson.current] = favAppClone[draggedOverPerson.current];
  //   favAppClone[draggedOverPerson.current] = temp;
  //   const canvasNodes = [...WorkspaceInfo.getWorkspacInfo()[0]?.nodes];
  //   setfavSoftwareResponse(favAppClone);
  //   setfavSoftwareResponseCopy(favAppClone);
  // }
  // const dragOverClick = (e) => {
  //   e.preventDefault();
  // }

  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };


  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const getContextDetails = async () => {
    const context = await app.getContext();
    sessionStorage.setItem('deviceInfo',context?.app?.host?.clientType);
    setDeviceInfo(`${sessionStorage.getItem('deviceInfo')}`);
  }

  useEffect(() => {
    setFromBrowser(sessionStorage.getItem('fromBrowser'));
    getContextDetails();
    // Add listener to update styles
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => onSelectMode(e.matches ? 'dark' : 'light'));
    // Setup dark/light mode for the first time
    onSelectMode(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
    // alert(`device info ::: ${sessionStorage.getItem('deviceInfo')}`);
    //Mobile Focus
    // setVisibilityValue(`/n ${document.hidden}  /nn ${document.visibilityState}`);
    if(sessionStorage.getItem('AppOpen') === 'true' &&  sessionStorage.getItem('browser') === 'true') {
      refreshStatsInfo();
    }
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        // setVisibilityValue(`${document.hidden} ${sessionStorage.getItem('AppOpen')} ${ typeof sessionStorage.getItem('AppOpen')}`);
        if((sessionStorage.getItem('AppOpen') === 'true' || sessionStorage.getItem('AppOpen') === true) && sessionStorage.getItem('browser') !== 'true') {
        //  setVisibilityValue(`IF /n ${document.hidden}  /nn ${document.visibilityState} /n ${sessionStorage.getItem('AppOpen')}`);
          // sessionStorage.setItem('pageHidden',false);
          refreshStatsInfo();
        }
        else {
        //  setVisibilityValue(`appopen not true /n ${document.hidden}  /nn ${document.visibilityState} ${sessionStorage.getItem('AppOpen')}`);
          // if(sessionStorage.getItem('pageHidden') === 'true' && sessionStorage.getItem('checkAppOpen') !== 'true') {
          //   sessionStorage.setItem('pageHidden',false);
          //   sessionStorage.setItem('checkAppOpen',false);
          //   reloadPage();
          // } 
        }
      }
      else {
        sessionStorage.setItem('pageHidden',true);
        // setVisibilityValue(`ELSE /n ${document.hidden} /nn ${document.visibilityState} `);
        // alert(`Hidden,..,${document.visibilityState}`);
      }
    });
    // web focus
    window.addEventListener("focus", () => {
      if(sessionStorage.getItem('AppOpen') === 'true' &&  sessionStorage.getItem('browser') !== 'true') {
        refreshStatsInfo();
      }
      // setVisibilityValue(`focus on  /n ${document.hidden} /nn ${document.visibilityState}`);
      // if(sessionStorage.getItem('AppOpen') == 'true'){
      //   let iApprovalCount = 0;
      //   if(StatsInfo.getStatsInfo() !== null && Object?.keys(StatsInfo.getStatsInfo())?.length !== 0) {
      //    let approvals = StatsInfo.getStatsInfo()?.IApprovalCount;
      //    if(approvals !==  null && Object.keys(approvals)?.length !== 0){
      //      iApprovalCount = approvals?.Self + approvals?.Delegate;
      //      if(iApprovalCount > 0) {
      //        let dynamicApps = [];
      //        getAppStatsInfo(dynamicApps);
      //      }
      //    }
      //   }
      // }
    });
    let pulltoRefresherObserver = setPullToRefreshObserver.getPullToRefresh().subscribe((res) => {
      if(res === true) {
        pulltoRefresherObserver.unsubscribe();
        if(pullToRefreshEnable.getPullToRefresh() === true) {
          pullToRefreshEnable.setPullToRefresh(false);
          handleRefresh();
        } 
      }
    })
    setTimeout(() => {
      if(document.getElementById('scroll_view_ele')) {
        // scrollRef.current.scrollTop = scrollInfo.getScrollInfo();
        document.getElementById('scroll_view_ele').scrollTop = sessionStorage.getItem('scrollPosition');
      }
    }, 100);

    if(sessionStorage.getItem('browser') === 'true') attachPopStateEventListner();

    // window.addEventListener("blur", () =>  {
    //   setVisibilityValue(`Blur on /n ${document.hidden} /nn ${document.visibilityState}`);
    // });
    // sessionStorage.setItem('currentSelectedTab',0);
    let decodedTeamsToken = AuthService.decodeToken(sessionStorage.getItem('mobileAppAccessToken'));
    let userInfo = AuthService.readUserInfoFromToken(decodedTeamsToken);
    loggedInUserInfo.setLoggedInUserInfo(userInfo);
    // if(RefreshPage.getRefreshPage() == true ){
    //   setLoader(true);
    //   fetchCanvasDetails();
    // }
    // else{
    if(sessionStorage.getItem('checkAppOpen') !== 'true') {
      sessionStorage.setItem('currentSelectedTab',0);
      setLoaderObserver.sendLoaderValue(true);
      setLoader(true);
    }
    else {
      let newValue = sessionStorage.getItem('currentSelectedTab');
      newValue = typeof newValue === 'string' ? parseInt(newValue) : newValue;
      setValue(newValue);
    }
    if(sessionStorage.getItem('browser') === 'true') setAppFromBrowser(true);
    // if(sessionStorage.getItem('isPulltoRefresh') !== 'true') {
      if (WorkspaceInfo.getWorkspacInfo()?.length !== 0) {
        let dynamicApps = WorkspaceInfo.getWorkspacInfo().nodes?.filter((list) => constants.statsApps.includes(list?.software?.softwareCode));
        if(dynamicApps?.length != 0 || sessionStorage.getItem('isPulltoRefresh') == 'true') {
          let dynamic_Apps = WorkspaceAppsInfo.getWorkspaceSoftwareList()?.filter((list) => constants.statsApps.includes(list?.softwareCode));
          if(Object?.keys(StatsInfo.getStatsInfo())?.length != 0) addStatsForApps(StatsInfo.getStatsInfo(),true);
          else getAppStatsInfo(dynamic_Apps);
        }
        (WorkspaceAppsInfo.getWorkspaceSoftwareList()?.length !== 0) ?
          constructSoftwareList(WorkspaceAppsInfo.getWorkspaceSoftwareList(), WorkspaceInfo.getWorkspacInfo()) :
          fetchSoftwareList(WorkspaceInfo.getWorkspacInfo());
      }
      else fetchCanvasDetails(false);
    // }
    if (sessionStorage.getItem('selectedView')) {
      let view = sessionStorage.getItem('selectedView') === 'card' ? true : false;
      setCardView(view);
    }
    let userStatsinterval = null;
    //*** After login ,for every 5 min userStatistics api will call ***//
    userStatsinterval = setInterval(() => {
      if(localStorage.getItem('userStatistics')) {
        let startsArr = JSON.parse(localStorage.getItem('userStatistics'));
        if(startsArr?.length != 0 || startsArr?.length >= 5){
          callUserStatistics(startsArr);
        }
      }
    },300000);
    // Remove listener
    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {
      });
    }
  }, []);

  const attachPopStateEventListner = () => {
    window.addEventListener('popstate', (event) => {
      if(window.location?.href.endsWith('/card') && sessionStorage.getItem('browser') === 'true') {
        setAppClickObserver.sendAppClicked(false);
      }
    })
  }

  const refreshStatsInfo = () => {
    let dynamicApps = WorkspaceAppsInfo.getWorkspaceSoftwareList()?.filter((list) => list.softwareCode === sessionStorage.getItem('softwareCode'));
    getAppStatsInfo(dynamicApps);
  }

  const fetchDetails = async () => {
    try {
      let res = await createMobileCanvas();
      fetchCanvasDetails(false);
    }
    catch (err) {
      console.log("err mobile canvas", err);
    }
  }

  const fetchCanvasDetails = async (reload) => {
    try {
      // let response = await axios.get(constants.genericCanvasWorkspaceApi,constants.headers);
      let response = await genericCanvasWorkspaceApi();
      WorkspaceInfo.setWorkspaceInfo(response.data);
      // setCanvasRes(response.data);
      if (response.data?.length === 0 || response.data === null) {
        fetchDetails();
      }
      else {
        let dynamicApps = response.data[0].nodes?.filter((list) => constants.statsApps.includes(list.software.softwareCode));
        if (WorkspaceAppsInfo.getWorkspaceSoftwareList()?.length !== 0 && reload === false) {
          if(dynamicApps?.length !== 0 && dynamicApps !== undefined) {
            let dynamic_Apps = WorkspaceAppsInfo.getWorkspaceSoftwareList()?.filter((list) => constants.statsApps.includes(list.softwareCode));
            if(Object?.keys(StatsInfo.getStatsInfo())?.length !== 0) addStatsForApps(StatsInfo.getStatsInfo(),true);
            else getAppStatsInfo(dynamic_Apps);
          }
          constructSoftwareList(WorkspaceAppsInfo.getWorkspaceSoftwareList(), response.data);
        }
        else fetchSoftwareList(response.data);
      }
    }
    catch (err) {
      console.log("fetchcanvasDetala error", err);
      setLoader(false);
      setLoaderObserver.sendLoaderValue(false);
      setErrorMsg('Error While We fetch the Data!');
    }
  }

  const fetchSoftwareList = async (canvasData) => {
    try {
      // let res = await axios.get(constants.getSoftwareListOnCanvas(response.data[0]?.canvasName,response.data[0]?.canvasCode),constants.headers);
      // let res = await getSoftwareListOnCanvas(response.data[0]?.canvasName,response.data[0]?.canvasCode);
      let res = await getSoftwaresOnGroupCodeApi(constants?.workspaceGroupCode);
      let dynamicApps = canvasData[0]?.nodes?.filter((list) => constants.statsApps.includes(list.software.softwareCode));
      if(dynamicApps?.length !== 0 && dynamicApps !== undefined && sessionStorage.getItem('isPulltoRefresh') != 'true') {
        let dynamic_Apps = res.data?.filter((list) => constants.statsApps.includes(list.softwareCode));
        if(Object?.keys(StatsInfo.getStatsInfo())?.length !== 0) addStatsForApps(StatsInfo.getStatsInfo(),true);
        else getAppStatsInfo(dynamic_Apps);
      }
      WorkspaceAppsInfo.setWorkspaceSoftwareList(res.data);
      constructSoftwareList(res.data, canvasData);
      sessionStorage.setItem('isPulltoRefresh',false);
    }
    catch (err) {
      setLoader(false);
      setLoaderObserver.sendLoaderValue(false);
      // setErrorMsg('Error While We fetch the Data!');
    }
  }

  const constructSoftwareList = (softwareData, canvasData) => {
    let appsArr = Object.assign([], softwareData);
    let softwareList = [];
    let favAppslist = [];
    let favAppslistCodes = [];
    // appsArr = appsArr.filter((list) => (list.isDeleted === false));
    appsArr = appsArr.filter((list) => (list.isDeleted === false && (list.hasOwnProperty('isMobileViewEnabled') && list?.isMobileViewEnabled === true)));
    appsArr = appsArr?.map((list) => {
      let Obj = Object.assign({}, list);
      let [isFavourite, nodeId, favAppUsers] = checkFavApp(list, canvasData[0]?.nodes);
      Obj['canvasCode'] = `${canvasData[0]?.canvasCode}`;
      Obj['appIcon'] = Obj['iconUrl'].split("/")[Obj['iconUrl'].split("/").length - 1];
      //  Obj['isFavourite'] = checkFavApp(list,canvasData[0]?.nodes);
      Obj['favAppUsers'] = (favAppUsers?.length) ? favAppUsers : [];
      Obj['isFavourite'] = isFavourite;
      Obj['nodeId'] = nodeId;
      return Obj;
    });
    setTimeout(() => {
      setLoader(false);
      setLoaderObserver.sendLoaderValue(false);
    }, 2000);
    if (!appsArr.length) setNoData('No Data Available!');
    else {
      // appsArr.sort(getSortedOrder('softwareName'));
      // softwareList = appsArr.filter((list) => list.isFavourite === false);
      softwareList = Object.assign([], appsArr);
      softwareList.sort(getSortedOrder('softwareName'));
      // favAppslist = appsArr.filter((list) => list.isFavourite === true);
      if (canvasData[0]?.hasOwnProperty('nodes') && canvasData[0]?.nodes?.length !== 0) {
        canvasData[0]?.nodes?.forEach((list) => {
          if (list?.software?.isDeleted === false && (list?.software?.hasOwnProperty('isMobileViewEnabled') && list?.software?.isMobileViewEnabled === true)) {
            let Obj = Object.assign({}, list.software);
            let [isFavourite, nodeId, favAppUsers] = checkFavApp(list.software, canvasData[0]?.nodes);
            Obj['canvasCode'] = `${canvasData[0]?.canvasCode}`;
            Obj['appIcon'] = Obj['iconUrl'].split("/")[Obj['iconUrl'].split("/").length - 1];
            Obj['favAppUsers'] = (favAppUsers?.length) ? favAppUsers : [];
            Obj['isFavourite'] = isFavourite;
            Obj['nodeId'] = nodeId;
            favAppslist.push(Obj);
          }
        })
      }
      favAppslist = favAppslist?.filter((list) => list?.isFavourite === true);
      favAppslistCodes = favAppslist?.map((list) => { return list?.softwareCode });
    }
    if(Object?.keys(StatsInfo.getStatsInfo())?.length !== 0) {
      softwareList = softwareList?.map((list) => {
        let obj = Object.assign({},list);
        if(Object?.keys(StatsInfo.getStatsInfo())?.length !== 0 && constants?.statsApps?.includes(list.softwareCode)) obj['stats'] = constructStats(list);
        else obj['stats'] = 0;
        return obj;
      })
      favAppslist = favAppslist?.map((list) => {
        let obj = Object.assign({},list);
        if(Object?.keys(StatsInfo.getStatsInfo())?.length !== 0 && constants?.statsApps?.includes(list.softwareCode)) obj['stats'] = constructStats(list);
        else obj['stats'] = 0;
        return obj;
      })
    }
    FilteredsoftwareList.setFilteredsoftwareList(softwareList);
    FilteredFavAppList.setFilteredFavAppList(favAppslist);
    if (value === 1 && sessionStorage.getItem('currentSelectedTab') === '1') {
      if (softwares.length === 0) {
        setSoftwareResponse(softwareList);
        setSoftwareResponseCopy(softwareList);
      }
      // if (favsoftwares.length === 0) {
      //   setfavSoftwareResponse(favAppslist);
      //   setfavSoftwareResponseCopy(favAppslist);
      //   setFavSoftwareIds(favAppslistCodes);
      // }
    }
    else {
      setSoftwareResponse(softwareList);
      setSoftwareResponseCopy(softwareList);
      setfavSoftwareResponse(favAppslist);
      setfavSoftwareResponseCopy(favAppslist);
      setFavSoftwareIds(favAppslistCodes);
    }
    let favApp = Object.assign({}, selectedFavApp.getSelectedFavApp());
    //if user doesn't have the canvas ,then canvas is getting created after that app is getting favourited
    if (Object?.keys(favApp)?.length !== 0) {
      let selectedFavApp = {};
      selectedFavApp = softwareList?.filter((list) => list.softwareCode === favApp.softwareCode);
      // selectedFavApp['canvasCode'] = `${canvasData[0]?.canvasCode}`;
      favouriteApp(selectedFavApp[0]);
    }
    if (searchInput && localStorage.getItem('searchString'))  {
      filteredSearch(searchInput);
    }
    if(favAppslist?.length === 0) navigateToAllApps();
  }

  const constructStats = (list) => {
    let stats = StatsInfo.getStatsInfo();
    let resultStats = 0;
    //approval inbox
    if(list.softwareCode === 'NSW3e8e4b0886be4eff9f8ef4d94ea7a2a0') {
      let approvalStats = stats?.IApprovalCount;
      if(approvalStats !== null && Object?.keys(approvalStats)?.length !== 0 ){
        let total = 0;
        Object.entries(approvalStats)?.forEach(([key,value]) => {
          total = total + value;
        })
        // let total = approvalStats?.Self + approvalStats?.Delegate;
        if(total !== 0) resultStats = total;
      }
    }
    //itime and attendance
    if(list.softwareCode === 'NSW705e62d12e0747acb47e61dcb504aee4') {
      let itimeStats = stats?.Nc_ComplianceCount;
      if(itimeStats !== null && Object?.keys(itimeStats)?.length !== 0 ){
        let total = 0;
        Object.entries(itimeStats)?.forEach(([key,value]) => {
          if(typeof value === 'string') total = total + Number(value);
          else total = total + value;
        })
        // let total = itimeStats?.TotalNCDays;
        if(total !== 0) resultStats = total;
      }
    }
    return resultStats;
  }

  const checkFavApp = (soft, nodesList) => {
    let result = false;
    let nodeId = 0;
    let favAppUsers = [];
    let userInfo = loggedInUserInfo.getLoggedInUserInfo();
    let userName = (userInfo?.username) ? userInfo?.username : userInfo?.upn;
    let resultArr = nodesList?.filter((list) => list.software.softwareCode === soft.softwareCode);
    if (!(resultArr === undefined || resultArr?.length === 0)) {
      let software = resultArr[0]?.software;
      nodeId = resultArr[0]?.id;
      favAppUsers = resultArr[0]?.favAppUsers;
      // if(software.favAppUsers?.length) result = true;
      if (software.favAppUsers?.length && software?.favAppUsers?.includes(userName)) result = true;
      else result = false;
    }
    else result = false;
    // return [result, nodeId , favAppUsers];
    return [result, nodeId, favAppUsers];
  }

  const callOnClickApi = async (software) => {
    toastr.info(`Please wait while we fetch the data..`);
    try {
      // let response = await axios.post(url, properties,constants?.headers);
      let response = await onClickApi(software?.canvasCode, software?.softwareCode, properties);
      onclickRes = Object.assign({}, response?.data);
      onclickRes['softwareName'] = `${software.softwareName}`;
      onclickRes['softwareCode'] = `${software.softwareCode}`;
      onclickRes['iconUrl'] = `${software?.appIcon}`;
      appNavigationOnBehaviour(onclickRes,software);
    }
    catch (error) {
      toastr.error(`Failed to fetch the data!`);
    }
  }

  const appNavigationOnBehaviour = (app,software) => {
    switch (app?.behaviour) {
      case 'DEFAULT':
        defaultApp(app);
        break;
      case 'NAVIGATE':
        navigateApps(app,software);
        break;
    }
  }

  const navigateApps = (app,software) => {
    let device = sessionStorage.getItem('browser') ? 'web' : 'mobile';
    app['showHeader'] = true;
    // if(app.softwareName?.toLowerCase() === 'bold stories') {
    //   app['showHeader'] = false;
    //   navigate('/iframe', { state: app });
    // }
    // else 
    if (app.object?.hasOwnProperty('token')) {
      // app['browserUrl'] = app.object?.applicationUrl + '?accessToken=' + app.object?.token;
      app['object'] = `${app.object?.applicationUrl}${app.object?.token}`;
      app['showHeader'] = false;
      sessionStorage.setItem('checkAppOpen',true);
      setAppClickObserver.sendAppClicked(true);
      navigate('/iframe', { state: app });
    }
    else {
      if((sessionStorage.getItem('deviceInfo')?.toLowerCase() === 'web' || sessionStorage.getItem('deviceInfo')?.toLowerCase() === 'desktop') && app.object?.includes('sharepoint.com')) device = 'web';
      app['object'] = getObjectUrl(app.object,device);
      console.log("resultUrl",app['object']);
      // microsoftTeams.app.openLink(`${app.object}`);
      if((sessionStorage.getItem('deviceInfo')?.toLowerCase() === 'web' || sessionStorage.getItem('deviceInfo')?.toLowerCase() === 'desktop' || sessionStorage.getItem('browser') === 'true') && app.object?.includes('sharepoint.com')) {
        window.open(app['object'], "_blank");
        return;
      }
      else if (app['object']?.includes('powerbi')) {
        sessionStorage.setItem('checkAppOpen',true);
        setAppClickObserver.sendAppClicked(true);
        navigate('/powerbi', { state: app });
      }
      else if(app['object']?.includes('/stage/')) {
        // app['object'] = getObjectUrl(app.object,device);
        sessionStorage.setItem('checkAppOpen',true);
        if(constants?.statsApps?.includes(software.softwareCode)) {
          // sessionStorage.setItem('AppOpen',true);
          sessionStorage.setItem('softwareCode',software.softwareCode);
        }
        microsoftTeams.app.openLink(`${app.object}`).then(
          (value) => {
            // console.log("success");
            // if(constants?.statsApps?.includes(software.softwareCode)){
              // sessionStorage.setItem('AppOpen',true);
            //   // setTimeout(() => {
            //   //   if(sessionStorage.getItem('AppOpen') == 'true') {
            //   //     let iApprovalCount = 0;
            //   //     if(StatsInfo.getStatsInfo() !== null && Object?.keys(StatsInfo.getStatsInfo())?.length !== 0) {
            //   //      let approvals = StatsInfo.getStatsInfo()?.IApprovalCount;
            //   //      if(approvals !=  null && Object.keys(approvals)?.length != 0) {
            //   //        iApprovalCount = approvals?.Self + approvals?.Delegate;
            //   //        if(iApprovalCount > 0) {
            //   //          let dynamicApps = [];
            //   //          getAppStatsInfo(dynamicApps);
            //   //        }
            //   //      }
            //   //     }
            //   //   }
            //   // },180000);
            // } 
          },
          (error) => {
             console.log("openLink fail: " ,error);
          }
        ).catch((err) => {
             console.log("openLink error: " + err);
        });
      }
      else { 
        //  window.open(app['object'], "_blank");
        if(constants?.statsApps?.includes(software.softwareCode)) {
          sessionStorage.setItem('softwareCode',software.softwareCode);
        }
        app['showHeader'] = false;
        app['object'] = `${app.object}`;
        sessionStorage.setItem('checkAppOpen',true);
        setAppClickObserver.sendAppClicked(true);
        navigate('/iframe', { state: app });
      }
    }
  }

  const defaultApp = (app) => {
    window.open(`${app.object}`)
  }

  const addAppAsFavourite = (e, software) => {
    // e.preventDefault();
    let softwareObj = Object.assign({}, software);
    e.stopPropagation();
    selectedFavApp.setSelectedFavApp(software);
    // if(software.hasOwnProperty('canvasCode') && (software.canvasCode == undefined || software.canvasCode == null || software.canvasCode == '' || software.canvasCode == 'undefined')){
    //   fetchDetails();
    // }
    // else
    let stats = setUserStatisticsData( 
      "MOBILE_APP_FAV",
      "MOBILE",
      software.canvasCode,
      software.softwareName,
      software.softwareCode,
      software?.projectCode, 
      loggedInUserInfo.getLoggedInUserInfo()?.mid);
     if (value === 1 && sessionStorage.getItem('currentSelectedTab') === '1') {
      let data = FilteredsoftwareList.getFilteredsoftwareList()?.filter((list) => list.softwareCode === software.softwareCode);
      softwareObj = Object.assign({}, data[0]);
      let image = document.getElementById(`${software.softwareCode}`).src;
      document.getElementById(`${software.softwareCode}`).src = (image.includes(constantFile.favIcon)) ? `/${constantFile.toggleFavIcon}` : `/${constantFile.favIcon}`;
    }
    favouriteApp(softwareObj);
    // callUserStatistics(stats);
  }

  const favouriteApp = (software) => {
    let removeFav = (software?.isFavourite === true) ? true : false;
    if (software.nodeId === 0) addAppToCanvas(software);
    else addFavourites(software, removeFav);
  }

  const addAppToCanvas = async (softwareObj) => {
    let node = createNodeObj(softwareObj.softwareCode);
    let versionNum = 0;
    try {
      let response = await saveCanvas(softwareObj.canvasCode, node, versionNum);
      let worksapceInfo = [];
      worksapceInfo.push(response.data);
      WorkspaceInfo.setWorkspaceInfo(worksapceInfo);
      let software = getUpdatedSoftwareNode(response.data.nodes, softwareObj);
      software['canvasCode'] = response.data?.canvasCode;
      let removeFav = (software?.isFavourite === true) ? true : false;
      addFavourites(software, removeFav);
    }
    catch (error) {

    }
  }

  const addFavourites = async (node, removeFav) => {
    try {
      // if (node.software.favAppUsers.length && node.software.favAppUsers.includes(this.loggedInUserInfo.username)) {
      let payLoad = getFavAppPayLoad(node, removeFav);
      let favRes = await addAsFavAppApi(node.canvasCode, payLoad);
      // let versionNum = 0;
      // let favRes = await updateCanvas(node.canvasCode,versionNum,payLoad);
      let worksapceInfo = [];
      worksapceInfo.push(favRes.data);
      WorkspaceInfo.setWorkspaceInfo(worksapceInfo);
      let msg = '';
      if (value === 1 && sessionStorage.getItem('currentSelectedTab') === '1') {
        let image = document.getElementById(`${node.softwareCode}`).src;
        msg = (image.includes(constantFile.favIcon)) ? `Successfully removed from Favorites` : `Successfully added to Favorites`;
      }
      else msg = (removeFav) ? 'Successfully removed from Favorites' : 'Successfully added to Favorites';
      toastr.success(`${msg}`);
      selectedFavApp.setSelectedFavApp({});
      // toast.success(`${msg}`, {
      //   position: "top-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
      // toast("Hello Geeks");
      if (removeFav) deleteAppFromCanvas(node);
      else getRefreshData();
    }
    catch (err) {
      toastr.error(`Please try again later!`);
    }
  }
  const deleteAppFromCanvas = (deletedNode) => {
    let data = Object.assign([], WorkspaceInfo.getWorkspacInfo()[0]);
    // try {
    let appArray = [];
    data.nodes?.forEach((item) => {
      if (item.software.softwareName !== deletedNode.softwareName) {
        appArray.push(item);
      }
    });
    updateCanvasDetails(deletedNode.canvasCode, createNodeObjList(appArray));
    // }
    // catch (err) {
    // }
  }

  const updateCanvasDetails = async (canvascode, payload) => {
    try {
      let versionNumber = 0;
      let res = await updateCanvas(canvascode, versionNumber, payload);
      let worksapceInfo = [];
      worksapceInfo.push(res.data);
      WorkspaceInfo.setWorkspaceInfo(worksapceInfo);
      getRefreshData();
    }
    catch (err) {
      console.log("errr", err);
    }
  }

  const createNodeObjList = (softWareList) => {
    const nodeList = [];
    softWareList?.forEach((item) => {
      nodeList.push({
        id: item.id,
        software: {
          softwareCode: item.software.softwareCode
        },
      });
    });
    return nodeList;
  }

  const createFavAppNodeList = (softWareList) => {
    const nodeList = [];
    softWareList?.forEach((item) => {
      nodeList.push({
        id: item.nodeId,
        software: {
          softwareCode: item.softwareCode
        },
      });
    });
    return nodeList;
  }

  const getFavAppPayLoad = (node, removeFav) => {
    let payLoad = {};
    let userInfo = loggedInUserInfo.getLoggedInUserInfo();
    console.log("userInfo", userInfo);
    let userName = (userInfo?.username) ? userInfo?.username : userInfo?.upn;
    if (removeFav) {
      let index = node.favAppUsers.indexOf(userName);
      node.favAppUsers.splice(index, 1);
      payLoad = {
        "software": {
          "softwareCode": node.softwareCode,
          "favAppUsers": node.favAppUsers
        },
        "id": node.nodeId
      }
    }
    else {
      node.favAppUsers.push(userName);
      payLoad = {
        "software": {
          "softwareCode": node.softwareCode,
          "favAppUsers": node.favAppUsers
        },
        "id": node.nodeId
      }
    }
    return payLoad;
  }
  const getUpdatedSoftwareNode = (nodes, softwareObj) => {
    nodes.forEach((list) => {
      if (list.software.softwareName === softwareObj.softwareName && list.software.softwareCode === softwareObj.softwareCode) {
        softwareObj = list.software;
        softwareObj['nodeId'] = list.id;
        return;
      }
    })
    return softwareObj;
  }
  const createNodeObj = (swCode) => {
    return {
      software: {
        softwareCode: swCode,
      },
    };
  }

  const getRefreshData = () => {
    if (WorkspaceInfo.getWorkspacInfo()?.length !== 0) {
      (WorkspaceAppsInfo.getWorkspaceSoftwareList()?.length !== 0) ?
        constructSoftwareList(WorkspaceAppsInfo.getWorkspaceSoftwareList(), WorkspaceInfo.getWorkspacInfo()) :
        fetchSoftwareList(WorkspaceInfo.getWorkspacInfo());
    }
    else fetchCanvasDetails(false);
  }

  const getSearchResults = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
    localStorage.setItem('searchString',e.target.value);
    filteredSearch(e.target.value);
  }

  const filteredSearch = (searchedElement) => {
    // let searchedElement = e.target.value;
    let favsoftwaresCopy = Object.assign([], FilteredFavAppList.getFilteredFavAppList());
    let softwaresCopy = Object.assign([], FilteredsoftwareList.getFilteredsoftwareList());
    if (searchedElement === "" || searchedElement === undefined) clearSearch();
    else {
      let favApps = favsoftwaresCopy?.filter((software) => {
        return software.softwareName.toLowerCase().includes(searchedElement.toLowerCase()) || software.description.toLowerCase().includes(searchedElement.toLowerCase()) || software?.keyWords?.toLowerCase()?.includes(searchedElement.toLowerCase())
      });
      let softwares = softwaresCopy?.filter((software) => {
        return software.softwareName.toLowerCase().includes(searchedElement.toLowerCase()) || software.description.toLowerCase().includes(searchedElement.toLowerCase()) || software?.keyWords?.toLowerCase()?.includes(searchedElement.toLowerCase())
      });
      setSoftwareResponse(softwares);
      setfavSoftwareResponse(favApps);
    }
  }

  const clearSearch = () => {
    let favsoftwaresCopy = Object.assign([], FilteredFavAppList.getFilteredFavAppList());
    let softwaresCopy = Object.assign([], FilteredsoftwareList.getFilteredsoftwareList());
    localStorage.removeItem('searchString');
    setSearchInput('');
    // setSearchOpen(false);
    setSoftwareResponse(softwaresCopy);
    setfavSoftwareResponse(favsoftwaresCopy);
  }
  const changeView = () => {
    let cardViewVar = cardView;
    cardViewVar = !cardViewVar;
    setCardView(cardViewVar);
    let view = (cardViewVar === true) ? 'card' : 'list';
    sessionStorage.setItem('selectedView', view);
  }

  const enableSearch = () => {
    let isSearch = searchOpen;
    isSearch = !isSearch;
    setSearchOpen(isSearch);
    setSearchExpandObserver.sendSearchExpand(isSearch);
  }

  // const people = [
  //   "homer",
  //   "marge",
  //   "lisa",
  //   "bart",
  //   "maggie",
  //   "lou",
  //   "lenny",
  //   "barney",
  //   "edna",
  //   "itchy",
  //   "scratchy",
  //   "krusty",
  //   "milhouse",
  //   "moe",
  //   "ralph",
  //   "seymour"
  // ]
  // const [items, setItems] = useState(people);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      // let softwares = Object.assign([], favsoftwares);
      let softwares = Object.assign([], FilteredFavAppList.getFilteredFavAppList());
      const oldIndex = softwares.findIndex((item) => item.softwareCode === active.id);
      const newIndex = softwares.findIndex((item) => item.softwareCode === over.id);
      softwares = arrayMove(softwares, oldIndex, newIndex);
      setfavSoftwareResponse(softwares);
      updateCanvasDetails(favsoftwares[0]?.canvasCode, createFavAppNodeList(softwares));
      // setfavSoftwareResponse((items) => {
      //   // return arrayMove(items, oldIndex, newIndex);
      //   // setItems((items) => {
      //   //   const oldIndex = items.indexOf(active.id);
      //   //   const newIndex = items.indexOf(over.id);
      //   //   return arrayMove(items, oldIndex, newIndex);
      // });
    }
  }

  const getAppStatsInfo = async(dynamicApps) => {
    // let payload = ['NSW92798e3797e441b79bccc67a34a14168','NSW7740d39bcdd14a1294444ef8e24e1a39'];
    let payload = [];
    dynamicApps?.forEach((list) => {
      payload.push(constantFile.statsInfoSoftwareCodesObj[list.softwareCode]);
    })
    // payload = dynamicApps.map((list) => { return list.software.softwareCode});
    try {
      let response = await getStatsData(payload);
      if(sessionStorage.getItem('AppOpen') === 'true' || sessionStorage.getItem('AppOpen') === true) {
        // let Obj = {
        //   'Self' : 9
        // }
        // response.data.IApprovalCount = Object.assign({},Obj);
        // let result = sessionStorage.getItem('count') ? sessionStorage.getItem('count') : 0;
        //  let Obj = {
        //   'TotalNCDays' : result
        // }
        // response.data.Nc_ComplianceCount = Object.assign({},Obj);
        // let res = {}
        // res.IApprovalCount =  null;
        // sessionStorage.setItem('AppOpen',false);      
        if(checkApprovalCountIsSame(response.data,StatsInfo.getStatsInfo()) === false) {
          let key = constants.statsKeyObj[sessionStorage.getItem('softwareCode')];
          let statsInfo = {};
          statsInfo = Object.assign({},StatsInfo.getStatsInfo());
          statsInfo[`${key}`] = Object.assign({},response.data[`${key}`]);
          StatsInfo.setStatsInfo(statsInfo);
          addStatsForApps(statsInfo,false);
        }
      }
      else {
        // let value = sessionStorage.getItem('count') ?  parseInt(sessionStorage.getItem('count')) : 0;
        // let Obj = {
        //   'Self' : value
        // }
        // response.data.IApprovalCount = Object.assign({},Obj);
        // console.log(response);
        // let res = {}
        // res.IApprovalCount =  null;
        StatsInfo.setStatsInfo(response.data);
        let refresh = (sessionStorage.getItem('scrollPosition') > 50) ? false : true;
        addStatsForApps(response.data,refresh);
      } 
    }
    catch (err){
      console.log("err in dynmai capp apis",err);
      if(sessionStorage.getItem('AppOpen') ===  'true') sessionStorage.setItem('AppOpen',true);
    }
  }

  const checkApprovalCountIsSame = (apiRes,getRes) => {
    let result = false;
    if(Object?.keys(apiRes)?.length !== 0 && Object?.keys(getRes)?.length !== 0){
      let presentCount = 0;
      let prevCount = 0;
      let key = (constantFile.statsKeyObj[sessionStorage.getItem('softwareCode')]) ? (constantFile.statsKeyObj[sessionStorage.getItem('softwareCode')]) : 'NA';
      if(apiRes[`${key}`] !== null && Object?.keys(apiRes[`${key}`])?.length !== 0){
        Object.entries(apiRes[`${key}`])?.forEach(([key,value]) => {
          presentCount = presentCount + value;
        })
      }
      if(getRes[`${key}`] !== null && Object?.keys(getRes[`${key}`])?.length !== 0){
        Object.entries(getRes[`${key}`])?.forEach(([key,value]) => {
          prevCount = prevCount + value;
        })
      }
      // presentCount = apiRes?.IApprovalCount?.Self + apiRes?.IApprovalCount?.Delegate;
      // prevCount = getRes?.IApprovalCount?.Self + getRes?.IApprovalCount?.Delegate;
      if(presentCount === prevCount) result = true;
    }
    return result;
  }

  /**
   * isRefresh will help us to re-render the list in Dom ..if it is true it will refresh the page ,otherwise it will change the count by id;
   */
  const addStatsForApps = (response,isRefresh) => {
      let softwareList = Object.assign([], FilteredsoftwareList.getFilteredsoftwareList());
      softwareList = softwareList?.map((list) => {
        let obj = Object.assign({},list);
        if(Object?.keys(response)?.length !== 0 && constants?.statsApps?.includes(list.softwareCode)) {
          obj['stats'] = constructStats(list);
          if(isRefresh === false) changeDomElements(list);
        } 
        else obj['stats'] = 0;
        return obj;
      })
      let favAppslist = Object.assign([], FilteredFavAppList.getFilteredFavAppList());
      favAppslist = favAppslist?.map((list) => {
        let obj = Object.assign({},list);
        if(Object?.keys(response)?.length !== 0 && constants?.statsApps?.includes(list.softwareCode)) {
          obj['stats'] = constructStats(list);
          if(isRefresh === false) changeDomElements(list);
        }
        else obj['stats'] = 0;
        return obj;
      })
      FilteredsoftwareList.setFilteredsoftwareList(softwareList);
      FilteredFavAppList.setFilteredFavAppList(favAppslist);
      if(isRefresh === true) {
        console.log("softwareList in refresh",softwareList);
        setSoftwareResponse(softwareList);
        setSoftwareResponseCopy(softwareList);
        setfavSoftwareResponse(favAppslist);
        setfavSoftwareResponseCopy(favAppslist);
      }
  }

  const changeDomElements = (list) =>{
    if(document.getElementById(`${list.softwareCode}stats`) !== null) {
      if(constructStats(list) > 0) document.getElementById(`${list.softwareCode}stats`).innerHTML = `${constructStats(list)}`;
      else document.getElementById(`${list.softwareCode}stats`).remove();
    }
    else {
      if(constructStats(list) > 0) {
        let childEle = document.createElement('div');
        childEle.setAttribute('class','stasClass');
        childEle.setAttribute('id',`${getStatsID(list?.softwareCode)}`);
        let text = document.createTextNode(`${constructStats(list)}`);
        childEle.appendChild(text);
        let parentEle = document.getElementById(`${list?.softwareCode}app`);
        parentEle.appendChild(childEle);
      }
    }
  }

  const navigateToAllApps = (e) => {
    let allAppIndex = 1;
    sessionStorage.setItem('currentSelectedTab',allAppIndex);
    let dynamicApps = WorkspaceAppsInfo.getWorkspaceSoftwareList()?.filter((list) => constants.statsApps.includes(list?.softwareCode));
    if(dynamicApps?.length !== 0) {
      if(Object?.keys(StatsInfo.getStatsInfo())?.length !== 0) addStatsForApps(StatsInfo.getStatsInfo());
      else getAppStatsInfo(dynamicApps);
    }
    setValue(allAppIndex);
  }
  const SortableCard = (props) => {
    const software = props.softwares;
    const {
      attributes,
      isDragging,
      listeners,
      setNodeRef,
      transform,
      transition
    } = useSortable({ id: props.id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      userSelect: "none",
      borderBottomColor: tokens.colorNeutralForeground3
      // touchAction: "none"
    };
    // const cardstyles = {
    //   backgroundColor: "white",
    //   padding: "1rem",
    //   width: "100px",
    //   margin: "0 1rem 1rem 0"
    // };

    return (
      <>{cardView ?
        <div ref={setNodeRef}
          // faded={isDragging}
          {...props}
          style={style}
          {...attributes}
          {...listeners}
          className="card_class" key={software?.softwareCode} onClick={((e) => appClick(e, software))} >
          {software?.isFavourite ? <img src="/toggle-fav.svg" alt="favIcon" className="fav_icon" onClick={((e) => addAppAsFavourite(e, software))} /> :
            <img src="/fav-icon.svg" alt="favIcon" className="fav_icon" onClick={((e) => addAppAsFavourite(e, software))} />
          }
          <div className="card_header">
            {/* <img src="/1touch.svg" className="image_class" alt="image" />  */}
            <img src={software?.appIcon} alt="software_icon" className="image_class" />
          </div>
          <div className="card_description">
            <div className="card__title" style={{ background: tokens.colorNeutralBackground3, color: tokens.colorNeutralForeground2 }}>
              {software?.softwareName}
            </div>
            {/* <div className="card__sub__title">
      {software?.description}
    </div> */}
          </div>
        </div> :
        <div
          ref={setNodeRef}
          // faded={isDragging}
          {...props}
          style={style}
          {...attributes}
          {...listeners}
          className={classNames('card_list_class', isDragging && 'dragged_list_class')} key={software?.softwareCode} onClick={((e) => appClick(e, software))} >
             {/* <div className="top_class">
              { (software.hasOwnProperty('stats') && software?.stats !== 0)  &&  <div className="stasClass">
                  {software.stats}
                </div> 
              } */}
              {software?.isFavourite ? <img src="/toggle-fav.svg" id={software?.softwareCode} alt="favIcon" className="fav_list_icon" onClick={((e) => addAppAsFavourite(e, software))} /> :
                <img src="/fav-icon.svg" alt="favIcon" id={software?.softwareCode} className="fav_list_icon" onClick={((e) => addAppAsFavourite(e, software))} />
              }
           {/* </div> */}
          <div className="card__list_header" id={getAppId(software?.softwareCode)}>
            <img src={software?.appIcon} alt="software_icon" className="image_list_class" />
            { (software.hasOwnProperty('stats') && software?.stats !== 0)  && <div className="stasClass" id={getStatsID(software?.softwareCode)}>
                  {software.stats}
                </div> 
              } 
          </div>
          <div className="card_list_description">
            <div className="card__title" style={{ background: tokens.colorNeutralBackground3, color: tokens.colorNeutralForeground2 }}>
              {software?.softwareName}
            </div>
            {/* </FluentProvider> */}
            <div className="card__sub__title" style={{ background: tokens.colorNeutralBackground3, color: tokens.colorNeutralForeground1 }}>
              {software?.description}
            </div>
          </div>
        </div>
      }
      </>
      // <div
      //   ref={setNodeRef}
      //   style={style}
      //   faded={isDragging}
      //   {...props}
      //   {...attributes}
      //   {...listeners}
      //   onClick={(e) => console.log("onclse")}
      // >
      //   <div style={cardstyles}>{props.name} </div>
      // </div>

    )
  }

  // const getRefreshPage = () => {
  //   console.log("get refrshhh");
  //   handleRefresh();
  // }

  const handleRefresh = async() => {
    navigateFavApps();
    reloadPage();
    setTimeout(() => {
      setSearchInput('');
      localStorage.removeItem('searchString');
      let allAppIndex = 0;
      sessionStorage.setItem('currentSelectedTab',allAppIndex);
      sessionStorage.setItem('AppOpen',false);
      setValue(allAppIndex);
      // reloadPage();
      if(sessionStorage.getItem('browser') === 'true') setAppFromBrowser(true);
      else setAppFromBrowser(false);
      // sessionStorage.setItem('isPulltoRefresh',false);
    },100);
  }

  const reloadPage = (e) => {
    console.log("reload***");
    // window.location.reload();
    // if(pullToRefreshEnable.getPullToRefresh() === true) {
      getContextDetails();
      // WorkspaceAppsInfo.setWorkspaceSoftwareList([]);
      StatsInfo.setStatsInfo({});
      // setLoaderObserver.sendLoaderValue(true);
      // setLoader(true);
      fetchCanvasDetails(true);
      pullToRefreshEnable.setPullToRefresh(false);
      // sessionStorage.setItem('isPulltoRefresh',false);
    // }
  }

  const disablSorting = () => {
    let result = false;
    if (favsoftwares?.length > 1) {
      if (searchInput.length !== 0) {
        result = (favsoftwaresCopy?.length === favsoftwares?.length) ? false : true;
      }
      else result = false;
    }
    else result = true;
    return result;
  }

  const navigateFavApps = (e) => {
    let allAppIndex = 0;
    sessionStorage.setItem('currentSelectedTab',allAppIndex);
    clearSearch();
    setValue(allAppIndex);
  }

  const getStatsID = (code) => {
    return `${code}stats`;
  }

  const getAppId = (code) => {
    return `${code}app`;
  }

  const onSelectMode = (mode) => {
    setMode(mode);
    // if (mode === 'dark'){
    // }
    // else {
    // }
  }

  const handleScroll = (event) => {
    scrollInfo.setScrollInfo(event.target.scrollTop);
    sessionStorage.setItem('scrollPosition',event.target.scrollTop);
    // setScrollPosition(event.target.scrollTop);
  }

  const getLoaderBackgroundColor = (mode) => {
    if (mode === 'dark') return '#000';
    else return '#fff';
  };


  if (isLoader && (!loading || (mode !== '' && sessionStorage.getItem('browser') === 'true'))) {
    return (
      <>
        { fromBrowser == 'true' ?
          <div className="loader_class_browser_gif" style={{ background: getLoaderBackgroundColor(mode) }}>
            {(themeString === `dark` || themeString === `contrast` || mode === `dark`) ? <img src="Ultima_web_dark_loader.gif" alt="loader" className="loader_gif_image_web" /> :
              <img src='Ultima_web_white_loader.gif' alt="loader" className="loader_gif_image_web" />}
          </div>  :
          <div className="loader_class_gif">
            {(themeString === `dark` || themeString === `contrast` || mode === `dark`) ? <img src="Ultima-Mobile_black.gif" alt="loader" className="loader_gif_image" /> :
              <img src='Ultima-Mobile_white.gif' alt="loader" className="loader_gif_image" />}
          </div>
        }
      </>
    )
  }

  if (errorMsg !== '' || noData !== '') {
    return <div className="errorMsg_class">{errorMsg ? errorMsg : noData}</div>
  }

  return (
    <>
      {!isLoader && (!loading || mode !== '') &&
        <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
          <FluentProvider
            theme={
              (themeString === "dark" || mode === "dark")
                ? {
                  ...teamsDarkTheme,
                  colorNeutralBackground3: `${constants.blackColor}`,
                  colorNeutralForeground1: `${constants.subTitleDark}`,
                  colorNeutralForeground2: `${constants.whiteColor}`,
                  colorNeutralForeground3: `${constants.borderColorDark}`,
                  colorNeutralBackground2: `${constants.searchBackgroundDark}`,
                  colorNeutralForeground4: `${constants.subTitleDark}`,
                  colorNeutralBackground1: `${constants.yellowColor}`,
                }
                : (themeString === "contrast" ||  mode === "dark")
                  ? {
                    ...teamsHighContrastTheme,
                    colorNeutralBackground3: `${constants.blackColor}`,
                    colorNeutralForeground1: `${constants.subTitleDark}`,
                    colorNeutralForeground2: `${constants.whiteColor}`,
                    colorNeutralForeground3: `${constants.borderColorDark}`,
                    colorNeutralBackground2: `${constants.searchBackgroundDark}`,
                    colorNeutralForeground4: `${constants.subTitleDark}`,
                    colorNeutralBackground1: `${constants.yellowColor}`,
                  }
                  : {
                    ...teamsLightTheme,
                    colorNeutralBackground3: `${constants.greyColor}`,
                    colorNeutralForeground1: `${constants.subTitlelight}`,
                    colorNeutralForeground2: `${constants.blueColor}`,
                    colorNeutralForeground3: `${constants.borderColorLight}`,
                    colorNeutralBackground2: `${constants.searchBackgroundLight}`,
                    colorNeutralForeground4: `${constants.blueColor}`,
                    colorNeutralBackground1: `${constants.blueColor}`,
                  }
            }
            style={{ background: tokens.colorNeutralBackground3, color: tokens.colorNeutralForeground1 }}
          >
                  <div className="card_header_container">
                  {searchOpen ?
                    <div className={ classNames('search_main_container', appFromBrowser && 'search_main_headerPresent_container') } >
                      <div className="search_container" style={{ background: tokens.colorNeutralBackground2 }}>
                        <span className="suffix_class"><img src="/search-icon.svg" alt="search_icon" /></span>
                        <input type="text" placeholder="Search" onChange={getSearchResults} value={searchInput} style={{ background: tokens.colorNeutralBackground2, color: tokens.colorNeutralForeground1 }} />
                        {searchInput && <span className="prefix_class"><img src="/teams-cross-copy.svg" alt="cross_icon" onClick={clearSearch} /></span>}
                      </div>
                      {/* <div><img src="/teams-cross.svg" className="clear_icon_class" alt="cross_icon" onClick={clearSearch} /></div> */}
                    </div>
                    :
                    <div className="search_class" onClick={enableSearch}>
                      <img src="/search-icon-grey.svg" alt="search_icon" className="search_icon_class" />
                    </div>
                  }
                  </div>
                  {/* <span>Mode ::: {mode}</span> */}
                  <PullToRefresh onRefresh={handleRefresh} resistance={5}>
                      <div className="card_container">
                        <Box sx={{ width: '100%' }}>
                          <Box sx={{ borderBottom: 1, borderColor: tokens.colorNeutralForeground3 }}>
                            {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}> */}
                            <Tabs TabIndicatorProps={{ style: { background: tokens.colorNeutralBackground1, height: "4px" } }} value={value} onChange={handleChange} aria-label="basic tabs example"
                              sx={{
                                '& .MuiTab-root': {
                                  textTransform: "none",
                                  font: "normal 1.3em SourceSansProRegular",
                                  letterSpacing: "0em",
                                  // color: '#064674'
                                  color: tokens.colorNeutralForeground4
                                },
                                '& .Mui-selected': {
                                  // color: "#064674 !important",
                                  color: `${tokens.colorNeutralForeground4} !important`,
                                  font: "normal 1.3em SourceSansProSemiBold",
                                }
                              }}>
                              <Tab label="Favorites" {...a11yProps(0)} />
                              <Tab label="All" {...a11yProps(1)} />
                              {/* <div className="view_container">
                                <img  className="card_view_class" src="/refresh.svg" alt="refresh_icon" onClick={(e) => reloadPage(e)} />
                                {cardView ? <img src="/card_view.svg" alt="box_view" className="card_view_class" onClick={changeView} /> : <img src="/menu-light.svg" alt="list_view" className="card_view_class card_list_img" onClick={changeView} />} 
                            </div> */}
                            </Tabs>
                          </Box>
                          <CustomTabPanel value={value} index={0}>
                            {/* { favsoftwares?.length !== 0 &&
                  <> */}
                            {/* <div className="div_title">My Favourites</div> */}
                            {cardView ?
                              <>
                                {favsoftwares?.length !== 0 ? <DndContext
                                  autoScroll={true}
                                  sensors={sensors}
                                  collisionDetection={closestCenter}
                                  onDragEnd={handleDragEnd}>
                                  <SortableContext items={favsoftwaresIDs} disabled={disablSorting()} >
                                    <div className="card_sub_container">
                                      {
                                        favsoftwares?.map((software, index) => (
                                          // <SortableCard key={name} name={name} index={index} />
                                          <SortableCard key={software.softwareCode} softwares={software} id={software.softwareCode} />
                                        ))
                                      }
                                    </div>
                                  </SortableContext>
                                </DndContext> : favsoftwaresCopy?.length === 0 ?
                                  <div className="no_apps_class all_apps_navigation">
                                    <img src='drag-drop.svg' alt="drag_img" className="drag_img_class" onClick={(e) => navigateToAllApps(e)} />
                                    Manage Favorites
                                  </div>
                                  :
                                  <div className="no_apps_class">
                                    <img src='drag-drop.svg' alt="drag_img" />
                                    No favorites found!
                                  </div>
                                }
                              </> :
                              <>
                                {favsoftwares?.length !== 0 ? <DndContext
                                  autoScroll={true}
                                  sensors={sensors}
                                  collisionDetection={closestCenter}
                                  onDragEnd={handleDragEnd}>
                                  <SortableContext items={favsoftwaresIDs} disabled={disablSorting()} >
                                    <div className={ classNames( 'card_sub_list_container', appFromBrowser && 'card_sub_list_container_fromBrowser')} onScroll={handleScroll} id="scroll_view_ele">
                                      {
                                        favsoftwares?.map((software, index) => (
                                          // <SortableCard key={name} name={name} index={index} />
                                          <SortableCard key={software.softwareCode} softwares={software} id={software.softwareCode} />
                                        ))
                                      }
                                    </div>
                                  </SortableContext>
                                </DndContext> : favsoftwaresCopy?.length === 0 ?
                                  <div className="no_apps_class all_apps_navigation">
                                    <img src='drag-drop.svg' alt="drag_img" className="drag_img_class" onClick={(e) => navigateToAllApps(e)} />
                                    Manage Favorites
                                  </div> :
                                  <div className="no_apps_class">
                                    No favourites found!
                                  </div>
                                }
                              </>
                            }
                            {/* </>
                  } */}
                          </CustomTabPanel>
                          <CustomTabPanel value={value} index={1}>
                            {/* { softwares?.length !== 0 &&
                    <> */}
                            {/* <div className="div_title">All Apps</div> */}
                            {cardView ?
                              <div className="card_sub_container">
                                {softwares?.length !== 0 ?
                                  <>
                                    {
                                      softwares?.map((software) => {
                                        return (
                                          <div className="card_class" key={software?.softwareCode} onClick={((e) => appClick(e, software))} >
                                            {software?.isFavourite ? <img src="/toggle-fav.svg" alt="favIcon" id={software?.softwareCode} className="fav_icon" onClick={((e) => addAppAsFavourite(e, software))} /> :
                                              <img src="/fav-icon.svg" alt="favIcon" id={software?.softwareCode} className="fav_icon" onClick={((e) => addAppAsFavourite(e, software))} />
                                            }
                                            <div className="card_header">
                                              {/* <img src="/1touch.svg" className="image_class" alt="image" />  */}
                                              <img src={software?.appIcon} alt="software_icon" className="image_class" />
                                            </div>
                                            <div className="card_description">
                                              <div className="card__title">
                                                {software?.softwareName}
                                              </div>
                                              {/* <div className="card__sub__title">
                                        {software?.description}
                                      </div> */}
                                            </div>
                                          </div>
                                        )
                                      })
                                    }
                                  </> :
                                  <div className="no_apps_found">
                                    No Apps Found!
                                  </div>
                                }
                              </div> :
                              <div className={ classNames('card_sub_list_container', appFromBrowser && 'card_sub_list_container_fromBrowser')}  onScroll={handleScroll} id="scroll_view_ele" >
                                {softwares?.length !== 0 ?
                                  <>
                                    {
                                      softwares?.map((software) => {
                                        return (
                                          <div className="card_list_class" key={software?.softwareCode} onClick={((e) => appClick(e, software))} style={{ borderBottomColor: tokens.colorNeutralForeground3 }}>
                                            {/* <div className="top_class"> */}
                                            {/* { (software.hasOwnProperty('stats')  && software?.stats !== 0 )  &&  <div className="stasClass">
                                                {software.stats}
                                            </div> 
                                            } */}
                                            {software?.isFavourite ? <img src="/toggle-fav.svg" alt="favIcon" className="fav_list_icon" id={software?.softwareCode} onClick={((e) => addAppAsFavourite(e, software))} /> :
                                              <img src="/fav-icon.svg" alt="favIcon" className="fav_list_icon" id={software?.softwareCode} onClick={((e) => addAppAsFavourite(e, software))} />
                                            }
                                            {/* </div> */}
                                            <div className="card__list_header" id={getAppId(software?.softwareCode)}>
                                              {/* <img src="/1touch.svg" className="image_class" alt="image" />  */}
                                              <img src={software?.appIcon} alt="software_icon" className="image_list_class" />
                                              {(software.hasOwnProperty('stats')  && software?.stats !== 0 )  &&  <div className="stasClass" id={getStatsID(software?.softwareCode)}>
                                                {software.stats}
                                                </div> 
                                              }
                                            </div>
                                            <div className="card_list_description">
                                              <div className="card__title" style={{ background: tokens.colorNeutralBackground3, color: tokens.colorNeutralForeground2 }}>
                                                {software?.softwareName}
                                              </div>
                                              <div className="card__sub__title">
                                                {software?.description}
                                              </div>
                                            </div>                            
                                          </div>
                                        )
                                      })
                                    }
                                  </> :
                                  <div className="no_apps_found">
                                    No Apps Found!
                                  </div>
                                }
                              </div>
                            }
                            {/* </>
                  } */}
                          </CustomTabPanel>
                          {/* {value === 1 && <Fab size="medium" sx={fabStyles} onClick={(e) => navigateFavApps(e)} aria-label="home"><img src="/home.svg" className="fab_icon" alt="home" /></Fab>} */}
                        </Box>
                      </div>
                  </PullToRefresh>
                  {value === 1 && <div className="footer_home_icon"><Fab size="medium" sx={fabStyles} onClick={(e) => navigateFavApps(e)} aria-label="home"><img src="/home.svg" className="fab_icon" alt="home" /></Fab></div>}
         </FluentProvider>
        </TeamsFxContext.Provider>
      }
    </>
  )
  // return <a href="https://teams.microsoft.com/l/stage/a6b63365-31a4-4f43-92ec-710b71557af9/0?context=%7B%22contentUrl%22%3A%22https%3A%2F%2Fapps.powerapps.com%2Fplay%2Fe%2Fa9c92f31-5af8-e995-bae3-6e00e414eed3%2Fa%2Fc5966b29-7116-4b27-80a9-3ec952c3b01e%3FtenantId%3Dff355289-721e-4dd7-a663-afec62ab9d54%26source%3Dteamstab%26locale%3D%7Blocale%7D%26channelId%3D%7BchannelId%7D%26channelType%3D%7BchannelType%7D%26chatId%3D%7BchatId%7D%26groupId%3D%7BgroupId%7D%26hostClientType%3D%7BhostClientType%7D%26isFullScreen%3D%7BisFullScreen%7D%26entityId%3D%7BentityId%7D%26subEntityId%3D%7BsubEntityId%7D%26teamId%3D%7BteamId%7D%26teamType%3D%7BteamType%7D%26theme%3D%7Btheme%7D%26userTeamRole%3D%7BuserTeamRole%7D%22%2C%22websiteUrl%22%3A%22https%3A%2F%2Fapps.powerapps.com%2Fplay%2Fe%2Fa9c92f31-5af8-e995-bae3-6e00e414eed3%2Fa%2Fc5966b29-7116-4b27-80a9-3ec952c3b01e%3FtenantId%3Dff355289-721e-4dd7-a663-afec62ab9d54%26hint%3D831b49b4-1bfd-4998-b598-226180d2a284%26sourcetime%3D1698385626478%26source%3Dportal%22%7D" target="_self">link</a>
}

export default CardDetails;