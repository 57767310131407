const WorkspaceInfo = {
    canvasInfo : [],
    setWorkspaceInfo(data){
        this.canvasInfo = data
    },
    getWorkspacInfo(){
        return this.canvasInfo;
    }
}

export const WorkspaceAppsInfo = {
    appInfo : [],
    setWorkspaceSoftwareList(data){
        this.appInfo = data;
    },
    getWorkspaceSoftwareList(){
        return this.appInfo;
    }
}

export default WorkspaceInfo;