import { Subject } from 'rxjs';

const searchValueObserver = new Subject();
const loaderObserver = new Subject();
const searchExpandObserver = new Subject();
const appClickObserver = new Subject();
const pullToRefreshObserver = new Subject();



export const setSearchValueObserver = {
    sendMessage: data => searchValueObserver.next(data),
    clearMessages: () => searchValueObserver.next(),
    getMessage: () => searchValueObserver.asObservable()
};

export const setLoaderObserver = {
    sendLoaderValue : data => loaderObserver.next(data),
    getLoaderValue : () => loaderObserver.asObservable()
}

export const setSearchExpandObserver = {
    sendSearchExpand : data => searchExpandObserver.next(data),
    getSearchExpand : () => searchExpandObserver.asObservable()
}

export const setAppClickObserver = {
    sendAppClicked : data => appClickObserver.next(data),
    getAppClicked : () => appClickObserver.asObservable()
}
export const setPullToRefreshObserver = {
    sendPullToRefresh : data => pullToRefreshObserver.next(data),
    getPullToRefresh : () => pullToRefreshObserver.asObservable()
}