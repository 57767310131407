const url  = 'https://ultimaworks.ltimindtree.com/api/';
const constantFile = {
   envUrl : url,
   headers : {
    headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzY3AiOlsiMjAwMCJdLCJhcGlLZXkiOmZhbHNlLCJpc3MiOiJ1bHRpbWFxYS5taW5kdHJlZS5jb20iLCJtaWQiOiI2MTA2MDk3NCIsInNvdXJjZSI6Ik1pbmR0cmVlIiwiYXVkIjoidWx0aW1hd29ya3NxYS5sdGltaW5kdHJlZS5jb20sdWx0aW1hd29ya3NxYS5sdGltaW5kdHJlZS5jb20iLCJ1cG4iOiJNMTA2MDk3NEBtaW5kdHJlZS5jb20iLCJ1bmlxdWVfbmFtZSI6Ik0xMDYwOTc0QG1pbmR0cmVlLmNvbSIsIm5iZiI6MTY5NzcwMDM1OCwibmFtZSI6IkthbmRsYXBlbGxpIE5hdmFuZWV0aGEiLCJ0aGVtZSI6IkxpZ2h0IiwiZXhwIjoxNjk3NzAzOTU4LCJpYXQiOjE2OTc3MDAzNTgsIm9yZ19jb2RlIjoiT1JHMDAwMDA0IiwiZW1haWwiOiJLYW5kbGFwZWxsaS42MTA2MDk3NEBsdGltaW5kdHJlZS5jb20iLCJzdGF0dXMiOiJBY3RpdmUifQ.Tt0XSuDO_Kz0Pt_BgUMlaIO8vtrWDJpZUyoCcFZHpe8",
      }
   },
   workspaceGroupCode : 'GRP9a8d642c32134cdfb02b16377970e9b1',
   // onClickApi : `${url}studio/v1/generic-canvas/CNVS9dc3fb35d2f84e24a775b05c2602ede8/software/NSW78c1184687a44ea79db53687d2a9c91b/onClick`,
   // profileApi : `${url}studio/v1/user/myProfile`,
   // genericCanvasWorkspaceApi : `${url}studio/v1/generic-canvas?type=my_canvas`,
   // getSoftwareListOnCanvas(canvasName, canvasCode){
   //    const url = `${this.envUrl}studio/v1/software-group?canvasType=${canvasName}&canvasCode=${canvasCode}`;
   //    return url;  
   // },
   loginAPI(credential){
      const httpOptions = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: btoa(credential.password),
          From: credential.username,
          tokenRequired : credential.tokenRequired
        },
      };
      const httpBody = {
        From: credential.username,
        Authorization: btoa(credential.password),
        tokenRequired : credential.tokenRequired,
      };
      return [`${url}ultima-mobile/v1/mobileLogin`,httpBody,httpOptions];
      // return this.http.post(`${url}auth/v1/login`, httpBody, httpOptions);
      // .pipe(
      //   map((user) => {
      //     // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
      //     user.authdata = window.btoa(
      //       credential.username + ':' + credential.password
      //     );
      //   //  sessionStorage.setItem('currentUser', JSON.stringify(user));
      //     this.currentUserSubject.next(user);
      //     return user;
      //   })
      // );
   },
    greyColor : '#F7F7F7',
    darkBlue  : '#111732',
    blackColor : '#000000',
    blueColor : '#064674',
    whiteColor : '#fff',
    subTitlelight : '#666',
    subTitleDark : '#999',
    borderColorLight : '#CACACA',
    borderColorDark : '#363435',
    searchBackgroundLight : '#D3D3D3',
    searchBackgroundDark : '#363435',
    yellowColor : '#e3b525',
    favIcon : 'fav-icon.svg',
    toggleFavIcon : 'toggle-fav.svg',
    //approvalInbox,time and attendance
    statsApps : ["NSW3e8e4b0886be4eff9f8ef4d94ea7a2a0","NSW705e62d12e0747acb47e61dcb504aee4"],
    statsInfoSoftwareCodesObj : {
      'NSW3e8e4b0886be4eff9f8ef4d94ea7a2a0' : 'NSW43406b1db814437e8fe962c4c8b6cc27',
      'NSW705e62d12e0747acb47e61dcb504aee4' : 'NSW9cb17f529757458ca730a41be69c40fd'
    },
    statsKeyObj : {
      'NSW3e8e4b0886be4eff9f8ef4d94ea7a2a0' : 'IApprovalCount',
      'NSW705e62d12e0747acb47e61dcb504aee4' : 'Nc_ComplianceCount'
    },
    colorCodes : ['#6A70A9','#001954','#6C2888','#8A0054','#F0506E','#78CFDE'],
    softwareCodesList : [
      {
        "softwareName" : "iTime",
        "softwareCode" : "NSW705e62d12e0747acb47e61dcb504aee4"
      },
      {
        "softwareName": "Seat Allocation",
        "softwareCode": "NSW9ecd55526fd94561bbc832d26dc70a89"
      },
    ]
};


export default constantFile;